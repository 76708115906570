<form [formGroup]="getLocationForm" class="mt-4 mb-3">
    <div class="row ">
      <ng-container *ngIf="locationJson?.length">
        <ng-container *ngFor="let data of locationJson">


       
      <div
        [ngClass]="{'col-xl-6 col-sm-12 col-12 mt-2 mb-3 flex-column view-column-cell': isDialogue, 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 flex-column view-column-cell mb-4': !isDialogue}">

            <ng-container *ngIf="!data?.group">
              <custom-select [inputSelectDisableState]="disableField" [inputSelectArray]="data?.data" [inputSelectFormContolName]="data?.controlName"
                [inputSelectFormGroup]="getLocationForm" [inputSelectRequiredStatus]="data?.required"
                [inputSelectMutipleOptionStatus]="data?.multiSelect" [inputSelectGroupBy]="false"
                (inputEmitSelected)="onSelectLocation($event, data)" [inputAppearance]=""
                [enableClear]="true"
                [inputSelectPlaceholderLabel]="data?.name" [inputSelectErrorLabel]="data?.name + ' Required'">
              </custom-select>
            </ng-container>
            <ng-container *ngIf="data?.group">
              <custom-select [inputAppearance]=""  [inputSelectDisableState]="disableField" [inputSelectArray]="data?.groupData"
                [inputSelectFormContolName]="data?.controlName" [inputSelectFormGroup]="getLocationForm"
                [inputSelectRequiredStatus]="data?.required" [inputSelectMutipleOptionStatus]="data?.multiSelect"
                [inputSelectGroupBy]="true" (inputEmitSelected)="onSelectLocation($event, data)"
                [enableClear]="true"
                [inputSelectPlaceholderLabel]="data?.name" [inputSelectErrorLabel]="data?.name + ' Required'">
              </custom-select>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
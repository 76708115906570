import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, Subject, takeUntil } from 'rxjs';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { ApiMethod, Endpoints } from 'src/app/core/services/utils/constants';
import { FormValidatorService } from '../../services/form-validator/form-validator.service';
import { ModalModule } from '../modal/modal.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material/material.module';
import { MatElementsModule } from '../mat-elements/mat-elements.module';
@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrl: './location-form.component.css',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    CommonModule,
    RouterModule,
    MatElementsModule,
    MaterialModule,
  ]
})

export class LocationFormComponent implements OnInit {
  @Input() mode: any = "";
  @Input() config: any = {};
  @Input() isDialogue: boolean = false;
  @Input() disableField: boolean = false;
  location: FormGroup | any;
  locationJson: any = [];
  locationdetails: any = [];
  private destroy$ = new Subject<void>();

  constructor(
    private _loader: LoaderService,
    private formBuilder: FormBuilder,
    private _http: HttpService,
    private lodash: LodashService,
    private _formValidator: FormValidatorService
  ) {

  }

  ngOnInit(): void {
    this.initialDependency();
  }

  initialDependency(){
    this.locationJson = [];
    this.locationdetails = [];
    this.createForm();
    this.getLocationAPI(null, 0);
  }

  createForm() {
    this.location = this.formBuilder.group({});
  }

  getLeastRec():any {
    const selectedLocation = this.locationJson.find((x: any) => x.gotSelect == true);
    if (selectedLocation) {
      return selectedLocation;
    } else {
      const listOfIndex = this.locationJson?.map((x: any) => x.index);
      const maxValue = Math.max(...listOfIndex);
      return this.locationJson.find((x: any) => x.index == maxValue);
    }
  }

  getLocation(parent?: any, order?: number) {
    let endpoint: any = '';
    if (parent)
      endpoint = `${Endpoints.GET_LOCATION_DROPDOWN}?parent=${parent}`;
    else
      endpoint = Endpoints.GET_LOCATION_DROPDOWN;
    return this._http.requestCall(endpoint, ApiMethod.GET)
      .pipe(map((response: any) => {
        const kebabCase = this.lodash.kebabCase(Object.keys(response?.data)?.[0]);
        const name = Object.keys(response?.data)?.toString()?.replaceAll(',', '/');
        const alreadyExist = this.locationJson.some((x: any) => x.controlName == kebabCase);

        //For Group Select...............
        let groupDetails = [];
        for (let index = 0; index < Object.keys(response?.data).length; index++) {
          const element = Object.keys(response?.data)[index];
          let obj: any = {};
          obj.display_name = element;
          obj.group = response?.data[element];
          groupDetails.push(obj)
        }

        //...............For Group Select
        if (name) {
          if (alreadyExist) {
            this.locationJson.find((x: any) => x.controlName == kebabCase).data = response?.data?.[Object.keys(response?.data)?.[0]];
            this.locationJson.find((x: any) => x.controlName == kebabCase).group = 1 < Object.keys(response?.data).length;
            this.locationJson.find((x: any) => x.controlName == kebabCase).groupData = groupDetails;
          } else {

            this.locationJson.push({
              index: order,
              controlName: kebabCase,
              name: name,
              required: this.config?.required ?? false,
              multiSelect: this.config?.multiSelect ?? false,
              data: response?.data?.[Object.keys(response?.data)?.[0]],
              group: 1 < Object.keys(response?.data).length,
              groupData: groupDetails,
            })
          }
        }
        this.locationJson = this.locationJson?.sort((a: any, b: any) => a.index - b.index);

        return response;

      }), takeUntil(this.destroy$))
  }

  /**
   * @description
   *  When the location changed..............
   * @param ev current data
   */
  onSelectLocation(ev: any, data: any) {

    if (ev == null) 
      return
    if (typeof ev === "object" && ev !== null && !Array.isArray(ev)) {
      ev = [ev];
    }

    this.locationdetails = {};

    if (ev?.length) {
      this.locationJson?.forEach((element: any) => {
        element.gotSelect = false;
      });
      data.gotSelect = true;
    }
    let hasRemove = false;
    let removeIndex = 0;
    let removeLength = 0
    this.locationJson?.forEach((element: any, index: any) => {
      if (data?.index < element?.index || hasRemove) {
        if (!hasRemove) {
          if (this.locationJson[index - 1])
            this.locationJson[index - 1].gotSelect = true;
          removeIndex = index;
        }
        hasRemove = true;
        this.getLocationForm.removeControl(element?.controlName);
        removeLength += 1;
      }
    });
    this.locationJson?.splice(removeIndex, removeLength);
    const locationId = ev?.map?.((x: any) => x.id)?.toString();

    const listOfIndex = this.locationJson?.map((x: any) => x.index);
    let maxValue = 0;
    if (!listOfIndex?.length)
      maxValue = listOfIndex?.length
    else
      maxValue = Math.max(...listOfIndex);
    this._loader.show();
    this.getLocationAPI(locationId, maxValue + 1);
  }

  //..............When the location changed

  /**
 * @description
 * GET location details from API here...................
 * @param parent 
 */

  getLocationAPI(parent?: any, order?: number) {
    this.getLocation(parent, order).subscribe((response: any) => {
      this.locationJson?.forEach((element: any) => {
        this._loader.hide();
        let patchdata = '';
        if (this.mode == 'edit') {
          if (this.locationdetails?.[element?.controlName]) {
            const ids = this.locationdetails?.[element?.controlName]?.map((x: any) => x.id);
            patchdata = element?.data?.filter((x: any) => ids.includes(x.id));
          }
        }
        if (element?.required == true)
          this.getLocationForm.addControl(element?.controlName, new FormControl("", [this._formValidator.requiredValidationCheck(element?.name)]));
        else
          this.getLocationForm.addControl(element?.controlName, new FormControl("", []));
        if (this.mode == 'edit' && patchdata) {
          patchdata = (this.config?.multiSelect) ? patchdata : patchdata[0];
          this.getLocationForm.get(element?.controlName)?.setValue(patchdata);
        }
      });
    })
  }

  //...................GET location details from API here

  get getLocationForm(): FormGroup {
    return this.location
  }

  /**
   * @description
   * Filter array keyname id's............................
   * @param data 
   * @returns 
   */
  transformData(data: any, keyName: string) {
    return data?.map((item: any) => {
      let transformed: any = { id: item.id, key: keyName, display_name: item.display_name };

      if (item.child_keys.length > 0) {
        transformed.child = [];
        item.child_keys.forEach((key: string) => {
          transformed.child = transformed.child.concat(this.transformData(item[key], key));
        });
      }

      return transformed;
    });
  }

  //............................Filter array keyname id's

  setLocationDetails(locations: any) {
    let locationDetails: any = [];
    locations?.child_keys?.forEach((key: any) => {
      locationDetails = this.transformData(locations[key], key);
    });
    const locationdetails = this.flattenJson(locationDetails, true);
    this.locationdetails = this.lodash.groupBy(locationdetails, 'key');
    this.locationItreation(this.locationdetails, 0);
  }

  /**
   * @description
   * Location details load depand on user details............
   * @param data Location details
   * @param index current indec
   */

  locationItreation(data: any, index: number) {
    Object.keys(data)?.forEach((element: any, index: any) => {
      const ids = data[element]?.map((x: any) => x.id)?.toString();
      this.getLocationAPI(ids, index + 1);
    });
  }

  //............Location details load depand on user details

  get viewLocationDetails(): any {
    let groupDetails = [];
    for (let index = 0; index < Object.keys(this.locationdetails).length; index++) {
      const element = Object.keys(this.locationdetails)[index];
      let obj: any = {};
      obj.display_name = element;
      obj.group = this.locationdetails[element];
      obj.concatName = this.locationdetails[element]?.map((x: any) => x.display_name)?.toString();
      groupDetails.push(obj)
    }
    return groupDetails
  }

  /**
   * @description
   * Nested JSON built as flattern JSON................................
   * @param jsonData 
   * @param deleteMode 
   * @returns 
   */

  flattenJson(jsonData: any, deleteMode: boolean) {
    let result: any = [];

    function flatten(item: any) {
      result.push(item);
      if (item.child) {
        item.child.forEach(flatten);
        if (deleteMode) {
          delete item.child;
        }
      }
    }
    jsonData?.forEach(flatten);
    return result;
  }

  //................................Nested JSON built as flattern JSON

}
